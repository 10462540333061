import './App.css';
import myself from './img/myself.jpg';

function App() {
  return (
    <div className="App">
      <nav>
        <ul>
          <li><a href=''>about me</a></li>
          <li><a href='https://www.linkedin.com/in/ebding/'>linkedin</a></li>
          <li><a href='https://medium.com/@kaleandchicken'>my writing</a></li>
          <li><a href='https://www.instagram.com/media.ding/'>photography</a></li>
          <li><a href='https://www.youtube.com/channel/UCo5aawBt6RBmeEJ3uBdSm3A'>films</a></li>
        </ul>
      </nav>
      <div className='intro'>
        <div className='childBox'>
          <h1>Eric Ding</h1>
          hello world! I'm a <b>software engineer</b> with 
          backend, infrastructure, and full stack
          work experiences.
        </div>
        <div className='childBox'>
          <img src={myself} alt='picture of me :p'/>
        </div>
      </div>
    </div>
  );
}

export default App;
